@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 100;
  src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Thin.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Thin.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Thin.otf) format("opentype"); }

@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 200;
  src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Light.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Light.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Light.otf) format("opentype"); }

@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 300;
  src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-DemiLight.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-DemiLight.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-DemiLight.otf) format("opentype"); }

@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 400;
  src: local("NotoSansJP-Regular.otf"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Regular.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Regular.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Regular.otf) format("opentype"); }

@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 500;
  src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Medium.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Medium.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Medium.otf) format("opentype"); }

@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 700;
  src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Bold.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Bold.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Bold.otf) format("opentype"); }

@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 900;
  src: url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Black.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Black.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Black.otf) format("opentype"); }

/* @mixin sp-screen-m() {
	@include mq($max-width: $tab-brk-short-m) {
	    @content;
	}
}
 */
@keyframes flashing {
  0% {
    opacity: 0; }
  100% {
    opacity: 100; } }

* {
  margin: 0;
  padding: 0; }

html {
  min-height: 100%;
  position: relative;
  box-sizing: border-box; }

html *, html *:before, html *:after {
  box-sizing: inherit; }

img {
  width: 100%;
  display: block;
  vertical-align: middle; }

body {
  background-color: #000;
  color: #fff;
  font-size: 100%;
  line-height: 1.5;
  font-family: Noto Sans Japanese, "Hiragino Kaku Gothic Pro", Meiryo, sans-serif;
  padding-bottom: 30px; }

button, input {
  font-family: Noto Sans Japanese, "Hiragino Kaku Gothic Pro", Meiryo, sans-serif;
  font-size: 100%; }

a {
  color: #e60000;
  outline: none;
  cursor: pointer; }

button {
  outline: none; }

.tal {
  text-align: left; }

.tar {
  text-align: right; }

.tac {
  text-align: center; }

.fwb {
  font-weight: bold; }

.u-hover {
  transition: opacity .1s;
  display: inline-block; }
  .u-hover:hover {
    opacity: 0.6; }

.m0 {
  margin: 0; }

.m05r {
  margin: 0.5rem; }

.m1r {
  margin: 1rem; }

.m2r {
  margin: 2rem; }

.m3r {
  margin: 3rem; }

.m4r {
  margin: 4em; }

.mt0 {
  margin-top: 0; }

.mt05r {
  margin-top: 0.5rem; }

.mt1r {
  margin-top: 1rem; }

.mt2r {
  margin-top: 2rem; }

.mt3r {
  margin-top: 3rem; }

.mt4r {
  margin-top: 4rem; }

.ml0 {
  margin-left: 0; }

.ml05r {
  margin-left: 0.5rem; }

.ml1r {
  margin-left: 1rem; }

.ml2r {
  margin-left: 2rem; }

.ml3r {
  margin-left: 3rem; }

.ml4r {
  margin-left: 4rem; }

.mr0 {
  margin-right: 0; }

.mr05r {
  margin-right: 0.5rem; }

.mr1r {
  margin-right: 1rem; }

.mr2r {
  margin-right: 2rem; }

.mr3r {
  margin-right: 3rem; }

.mr4r {
  margin-right: 4rem; }

.mb0 {
  margin-bottom: 0; }

.mb05r {
  margin-bottom: 0.5rem; }

.mb1r {
  margin-bottom: 1rem; }

.mb2r {
  margin-bottom: 2rem; }

.mb3r {
  margin-bottom: 3rem; }

.mb4r {
  margin-bottom: 4rem; }

.p0 {
  padding: 0; }

.p05r {
  padding: 0.5rem; }

.p1r {
  padding: 1rem; }

.p2r {
  padding: 2rem; }

.p3r {
  padding: 3rem; }

.p4r {
  padding: 4em; }

.pt0 {
  padding-top: 0; }

.pt05r {
  padding-top: 0.5rem; }

.pt1r {
  padding-top: 1rem; }

.pt2r {
  padding-top: 2rem; }

.pt3r {
  padding-top: 3rem; }

.pt4r {
  padding-top: 4rem; }

.pl0 {
  padding-left: 0; }

.pl05r {
  padding-left: 0.5rem; }

.pl1r {
  padding-left: 1rem; }

.pl2r {
  padding-left: 2rem; }

.pl3r {
  padding-left: 3rem; }

.pl4r {
  padding-left: 4rem; }

.pr0 {
  padding-right: 0; }

.pr05r {
  padding-right: 0.5rem; }

.pr1r {
  padding-right: 1rem; }

.pr2r {
  padding-right: 2rem; }

.pr3r {
  padding-right: 3rem; }

.pr4r {
  padding-right: 4rem; }

.pb0 {
  padding-bottom: 0; }

.pb05r {
  padding-bottom: 0.5rem; }

.pb1r {
  padding-bottom: 1rem; }

.pb2r {
  padding-bottom: 2rem; }

.pb3r {
  padding-bottom: 3rem; }

.pb4r {
  padding-bottom: 4rem; }

.fzxs {
  font-size: x-small; }

.fzs {
  font-size: small; }

.fz70p {
  font-size: 70%; }

.fz80p {
  font-size: 80%; }

.fz90p {
  font-size: 90%; }

.fz100p {
  font-size: 100%; }

.fz110p {
  font-size: 110%; }

.fz120p {
  font-size: 120%; }

.fz130p {
  font-size: 130%; }

.fz140p {
  font-size: 140%; }

.fz150p {
  font-size: 150%; }

.fz160p {
  font-size: 160%; }

.fz170p {
  font-size: 170%; }

.fz180p {
  font-size: 180%; }

.fz190p {
  font-size: 190%; }

.fz200p {
  font-size: 200%; }

.w4e {
  width: 4em; }

.w3e {
  width: 3em; }

.w100p {
  width: 100%; }

.cb {
  clear: both; }

.fl {
  float: left; }

.fr {
  float: right; }

.fn {
  float: none; }

.l-content {
  width: 100%;
  max-width: 1080px;
  margin: 0 3.90625vw;
  position: relative; }
  .l-content__title {
    margin-bottom: 10px;
    padding: 13px 10px 10px;
    font-family: "Megrim", cursive;
    font-size: 28px;
    font-weight: normal;
    color: #e60000;
    letter-spacing: 0.15em;
    line-height: 1;
    text-transform: uppercase; }

.l-bg-image {
  position: fixed;
  width: 1200px;
  left: 50%;
  margin: 200px 0 0 -600px; }
  @media only screen and (max-width: 900px) {
    .l-bg-image {
      width: 187.5vw;
      margin: 40.625vw 0 0 -93.75vw; } }
  .l-bg-image::before, .l-bg-image::after {
    content: "";
    width: 1200px;
    height: 350px;
    background-color: #000;
    position: absolute;
    left: 0;
    transition: transform .7s .1s; }
    @media only screen and (max-width: 900px) {
      .l-bg-image::before, .l-bg-image::after {
        width: 187.5vw; } }
  .l-bg-image::before {
    transform: skewY(6deg);
    top: 0; }
  .l-bg-image::after {
    transform: skewY(6deg);
    bottom: 0; }
  .l-bg-image .l-dotpattern {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent url(../img/dot_pattern.png) repeat; }

.l-main {
  max-width: 1200px;
  position: relative;
  opacity: 0;
  transition: opacity .4s .8s;
  margin: 0 auto 80px; }
  .l-main:before {
    content: "";
    display: block;
    height: 132px; }
    @media only screen and (max-width: 900px) {
      .l-main:before {
        height: 28.4375vw; } }

.l-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 auto 45px; }
  .l-container > *:last-child {
    margin-bottom: 0; }

.p-page-title {
  margin: 0 0 20px;
  font-family: "Megrim", cursive;
  letter-spacing: .08em;
  width: 100%;
  color: #e60000;
  font-size: 3.5em;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  line-height: 1; }
  @media only screen and (max-width: 900px) {
    .p-page-title {
      margin: 0 0 7.8125vw;
      font-size: 9.375vw; } }
  .p-page-title span {
    margin-right: 20px;
    background: url(../img/page_tit_bg.png) center repeat-x; }
    @media only screen and (max-width: 900px) {
      .p-page-title span {
        margin: 0 auto; } }

.l-modal {
  position: fixed;
  z-index: 50;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transition: .4s .2s;
  transform: scale(0);
  opacity: 0;
  z-index: 50; }
  .l-modal::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    background-color: #000; }
  .l-modal .l-modal__content {
    opacity: 0;
    transform: scale(1.1);
    transition: .4s .6s; }
  .l-modal .l-modal__close {
    position: fixed;
    top: 40px;
    right: 40px;
    z-index: 60;
    letter-spacing: 0.15em; }
    .l-modal .l-modal__close a::after {
      content: "CLOSE";
      display: inline-block;
      font-family: "Khand", sans-serif;
      font-size: 20px;
      color: white;
      vertical-align: middle;
      margin-left: 10px; }
    .l-modal .l-modal__close a:hover {
      animation: flashing .1s infinite linear; }
    .l-modal .l-modal__close img {
      display: inline-block;
      width: 14px;
      height: auto;
      vertical-align: middle; }
    @media only screen and (max-width: 900px) {
      .l-modal .l-modal__close {
        top: 20px;
        left: 20px;
        right: auto;
        right: initial; }
        .l-modal .l-modal__close img {
          width: 32px;
          height: auto; }
        .l-modal .l-modal__close a::after {
          display: none; } }

.u-show-modal .l-modal {
  transform: scale(1);
  opacity: 1; }
  .u-show-modal .l-modal .l-modal__content {
    opacity: 1;
    transform: scale(1); }

.onloaded .l-main {
  opacity: 1; }

.onloaded .l-bg-image::before {
  transform: translateY(-350px) skewY(6deg); }

.onloaded .l-bg-image::after {
  transform: translateY(350px) skewY(6deg); }

.l-header {
  padding: 30px 0;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  transition: .6s .4s;
  transform: translateY(-130px); }
  @media only screen and (max-width: 900px) {
    .l-header {
      padding: 3.125vw 0; }
      .l-header.is-drawer-open {
        height: 100vh;
        background-color: #000; } }
  .l-header .l-header__inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: auto;
    padding: 0 20px; }
    @media only screen and (max-width: 900px) {
      .l-header .l-header__inner {
        flex-wrap: wrap;
        padding: 0 4.6875vw; } }

.onloaded .l-header {
  transform: translateY(0px); }

.p-logo {
  margin: 0;
  padding: 0; }
  .p-logo img {
    width: 275px; }
  @media only screen and (max-width: 900px) {
    .p-logo {
      margin-left: 1.5625vw; }
      .p-logo img {
        width: 50vw; } }

.p-button {
  display: none; }
  @media only screen and (max-width: 900px) {
    .p-button {
      display: block;
      margin: 1.25vw 0;
      width: 10.625vw;
      height: 10.625vw;
      background-color: transparent;
      background-image: url(../img/icon_menu@2x.png);
      background-size: 100%;
      border: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      text-indent: -9999px; } }

.p-nav {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 580px; }
  @media only screen and (max-width: 900px) {
    .p-nav {
      display: none; }
      .is-drawer-open .p-nav {
        padding-top: 3.125vw;
        display: block;
        width: 100%; } }

.p-nav__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  width: 560px;
  height: 65px;
  list-style-type: none; }
  @media only screen and (max-width: 900px) {
    .p-nav__content {
      width: 100%;
      height: auto;
      border-bottom: 1px solid #e60000; } }
  .p-nav__content li {
    padding: 0;
    height: 20px; }
    .p-nav__content li:nth-child(5n+1) {
      width: 78px; }
    .p-nav__content li:nth-child(5n+2) {
      width: 138px; }
    .p-nav__content li:nth-child(5n+3) {
      width: 123px; }
    .p-nav__content li:nth-child(5n+4) {
      width: 93px; }
    .p-nav__content li:nth-child(5n+5) {
      width: 123px; }
    .p-nav__content li:nth-child(n+6) {
      margin-top: 25px; }
    @media only screen and (max-width: 900px) {
      .p-nav__content li {
        border-top: 1px solid #e60000;
        height: auto; }
        .p-nav__content li:nth-child(odd) {
          border-right: 1px solid #e60000; }
        .p-nav__content li:nth-child(n) {
          width: 50%;
          margin-top: 0; } }
    .p-nav__content li a {
      display: block;
      font-family: "Khand", sans-serif;
      border-left: 3px solid maroon;
      text-decoration: none;
      height: 20px;
      line-height: 20px;
      padding-left: 10px;
      color: white;
      letter-spacing: .15em;
      padding-right: 1em;
      transition: .2s; }
      .p-nav__content li a:hover, .p-nav__content li a.active {
        color: #e60000;
        border-left: 3px solid #e60000; }
        @media only screen and (max-width: 900px) {
          .p-nav__content li a:hover, .p-nav__content li a.active {
            border: none; } }
      .p-nav__content li a.disabled {
        color: #555;
        border-left: 3px solid #999; }
        @media only screen and (max-width: 900px) {
          .p-nav__content li a.disabled {
            border: none; } }
      @media only screen and (max-width: 900px) {
        .p-nav__content li a {
          height: 18.75vw;
          line-height: 18.75vw;
          border: none;
          font-size: 3.125vw;
          padding: 0 0 0 4.6875vw; } }
    @media only screen and (max-width: 900px) {
      .p-nav__content li {
        width: 50%; } }

@media only screen and (max-width: 900px) {
  .p-nav__social {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 47.65625vw;
    margin: 12.5vw auto; } }

.p-nav__social a {
  display: block;
  line-height: 1;
  vertical-align: top;
  margin: 0;
  width: 20px;
  height: 20px;
  text-indent: -9999px;
  background-size: 100%;
  background-repeat: no-repeat; }
  .p-nav__social a:nth-child(2) {
    margin-top: 25px; }
    @media only screen and (max-width: 900px) {
      .p-nav__social a:nth-child(2) {
        margin-top: 0; } }
  .p-nav__social a img {
    vertical-align: bottom; }

.p-nav__social .p-social-twitter {
  background-image: url(../img/icon_twitter@2x.png); }
  @media only screen and (max-width: 900px) {
    .p-nav__social .p-social-twitter {
      width: 9.375vw;
      height: 7.5vw;
      background-image: url(../img/twitter_sp@2x.png); } }

.p-nav__social .p-social-facebook {
  background-image: url(../img/icon_facebook@2x.png); }
  @media only screen and (max-width: 900px) {
    .p-nav__social .p-social-facebook {
      width: 4.375vw;
      height: 8.59375vw;
      background-image: url(../img/facebook_sp@2x.png); } }

.p-nav__social .p-social-line {
  display: none; }
  @media only screen and (max-width: 900px) {
    .p-nav__social .p-social-line {
      display: block;
      width: 9.21875vw;
      height: 9.0625vw;
      background-image: url(../img/line_sp@2x.png); } }

.l-footer {
  background-color: #CC0000;
  text-align: center;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: absolute;
  bottom: 0;
  width: 100%; }
  .l-footer small {
    font-size: x-small;
    line-height: 1;
    vertical-align: middle;
    display: block; }

.l-ajust-footer {
  height: 40px;
  margin: 0; }

.l-top {
  max-width: 1300px;
  margin-bottom: 35px; }
  @media only screen and (max-width: 900px) {
    .l-top {
      margin-bottom: 15.625vw;
      padding-top: 0; } }
  .l-top .l-container {
    width: calc(850 / 900 * 100%);
    max-width: 1200px;
    padding: 0; }
  .l-top .l-content {
    background-image: url(../img/border_red_line.png);
    background-size: 100% 3px;
    background-repeat: repeat-x;
    margin-right: 0;
    margin-left: 0; }
    @media only screen and (max-width: 900px) {
      .l-top .l-content {
        width: 100%;
        max-width: none; } }

.l-keyvisual {
  position: relative; }
  .l-keyvisual__bg {
    margin-bottom: 30px;
    padding-top: 54%;
    position: relative;
    background: url(../img/key_pc.jpg) center/cover no-repeat;
    height: 700px;
    opacity: 0;
    transition: .8s 1.6s; }
    @media only screen and (max-width: 900px) {
      .l-keyvisual__bg {
        margin-bottom: 4.6875vw;
        padding: 0;
        height: 141.5625vw;
        background: url(../img/key_sp.jpg) center/cover no-repeat; } }
  .l-keyvisual__catchcopy {
    opacity: 0;
    transform: scale(1.2);
    transition: 2.6s .8s;
    margin: 0 auto 30px;
    width: calc(802 / 900 * 100%);
    max-width: 1100px;
    padding-top: calc(90 / 900 * 100%);
    height: 0;
    background-image: url(../img/top/txt_catchcopy_pc@2x.png);
    background-repeat: no-repeat;
    background-size: 100%;
    text-indent: -9999px; }
    @media only screen and (max-width: 900px) {
      .l-keyvisual__catchcopy {
        width: 82.8125vw;
        height: 31.25vw;
        background-image: url(../img/top/txt_catchcopy_sp@2x.png);
        margin-bottom: 2.34375vw; } }
  .l-keyvisual__bannerlist {
    position: absolute;
    top: 190px;
    right: 50px;
    width: 220px;
    list-style: none;
    opacity: 0;
    transform: translateY(20px);
    transition: .8s 2s; }
    @media only screen and (max-width: 900px) {
      .l-keyvisual__bannerlist {
        position: relative;
        top: 0;
        right: 0;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 6.25vw;
        width: auto; } }
    .l-keyvisual__bannerlist li {
      margin-bottom: 20px; }
      @media only screen and (max-width: 900px) {
        .l-keyvisual__bannerlist li {
          margin: 0 3.125vw;
          max-width: 43.75vw; } }
  .l-keyvisual__movie {
    position: absolute;
    top: 20px;
    right: 50px;
    width: 220px;
    list-style: none;
    opacity: 0;
    transform: translateY(20px);
    transition: .8s 2s; }
    @media only screen and (max-width: 900px) {
      .l-keyvisual__movie {
        position: relative;
        top: 0;
        right: 0;
        display: flex;
        margin-bottom: 6.25vw;
        width: auto; } }
    .l-keyvisual__movie li {
      margin-bottom: 20px; }
      .l-keyvisual__movie li .movie_header {
        font-family: "Khand", sans-serif;
        background-color: black;
        display: block;
        text-align: center; }
      .l-keyvisual__movie li::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        transform: translateY(0px);
        z-index: 2;
        display: block;
        width: 60px;
        height: 60px;
        background-image: url(../img/movie/icon_movie_play.png);
        background-size: 100%;
        content: "";
        pointer-events: none; }
      .l-keyvisual__movie li a {
        box-shadow: 3px 3px 3px rgba(1, 1, 1, 0.2); }
      @media only screen and (max-width: 900px) {
        .l-keyvisual__movie li {
          margin: 0 3.125vw;
          width: 43.75vw; } }

.p-main-catchcopy {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0 0 50px; }
  @media only screen and (max-width: 900px) {
    .p-main-catchcopy {
      padding-top: 20.3125vw;
      padding-left: 3.125vw;
      padding-right: 3.125vw;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap; } }
  .p-main-catchcopy img {
    vertical-align: top;
    height: auto;
    width: 120px;
    display: inline-block;
    transition: .8s 1.6s;
    margin-left: -40px; }
    @media only screen and (max-width: 900px) {
      .p-main-catchcopy img {
        width: 20.3125vw; } }
  @media only screen and (max-width: 900px) {
    .p-main-catchcopy .l-main-catchcopy__jijiiga {
      margin-left: -3.125vw; } }
  @media only screen and (max-width: 900px) {
    .p-main-catchcopy .l-main-catchcopy__orega {
      margin-left: 0;
      margin-right: -3.125vw; } }

.onloaded .l-keyvisual__bg {
  opacity: 1; }

.onloaded .l-keyvisual__catchcopy {
  transform: scale(1);
  opacity: 1; }

.onloaded .l-keyvisual__bannerlist {
  opacity: 1;
  transform: translateY(0); }

.onloaded .l-keyvisual__movie {
  opacity: 1;
  transform: translateY(0); }

/* @keyframes fadeInKV {
	0% {opacity: 0;}
	100% {opacity: 1;}
} */
.p-news {
  width: calc(690 / 1200 * 100%);
  max-width: 690px; }
  .p-news > *:last-child {
    margin-bottom: 0; }
  .p-news__list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0 10px;
    margin-bottom: 40px; }
    .p-news__list > dt:last-of-type,
    .p-news__list > dd:last-of-type {
      margin-bottom: 0px; }
    @media only screen and (max-width: 900px) {
      .p-news__list {
        display: block;
        margin-bottom: 4.6875vw;
        padding: 0; } }
    .p-news__list dt,
    .p-news__list dd {
      margin-bottom: 15px; }
      @media only screen and (max-width: 900px) {
        .p-news__list dt,
        .p-news__list dd {
          font-size: 3.4375vw; } }
    .p-news__list dt {
      width: 130px;
      letter-spacing: 0.15em;
      color: #e60000; }
      @media only screen and (max-width: 900px) {
        .p-news__list dt {
          margin-bottom: 5px;
          width: 100%; } }
    .p-news__list dd {
      width: calc(100% - 130px); }
      @media only screen and (max-width: 900px) {
        .p-news__list dd {
          width: 100%; } }
    .p-news__list a {
      display: block;
      text-decoration: none;
      color: #fff; }
      .p-news__list a:hover {
        color: #e60000; }
  .p-news__footer {
    text-align: right; }
  .p-news__more {
    display: inline-flex;
    font-size: 17px;
    font-family: "Khand", sans-serif;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.15em; }
    @media only screen and (max-width: 900px) {
      .p-news__more {
        margin-bottom: 6.25vw; } }
    .p-news__more::before {
      display: inline-block;
      margin-right: 8px;
      width: 7px;
      height: 21px;
      background-image: url(../img/top/icon_arrow_right@2x.png);
      background-size: contain;
      background-repeat: no-repeat;
      content: ""; }

.p-widget {
  position: relative;
  width: calc(470 / 1200 * 100%);
  max-width: 470px; }
  .p-widget__link {
    position: absolute;
    top: 10px;
    right: 0;
    font-family: "Khand", sans-serif;
    letter-spacing: 0.15em;
    text-decoration: none; }
    @media only screen and (max-width: 900px) {
      .p-widget__link {
        top: 1.5625vw; } }

.p-bannerlist {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 640px;
  margin: 0 auto;
  list-style: none; }
  .p-bannerlist > *:last-child {
    margin-bottom: 0; }
  @media only screen and (max-width: 900px) {
    .p-bannerlist {
      display: flex;
      width: auto; } }
  .p-bannerlist li {
    width: 200px; }
    @media only screen and (max-width: 900px) {
      .p-bannerlist li {
        margin: 0 auto 3.125vw;
        width: 58.59375vw; } }

.p-top-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  font-family: "Khand", sans-serif;
  color: #e60000;
  font-size: 160%;
  /* .p-countdown__dd {
		&::after {
			content: "d";
		}
	}
	.p-countdown__hh {
		&::after {
			content: "h";
		}
	}
	.p-countdown__mm {
		&::after {
			content: "m";
		}
	}
	.p-countdown__ss {
		&::after {
			content: "s";
		}
	}
	.p-countdown__ms {
		&::after {
			content: "ms";
		}
	} */ }
  @media only screen and (max-width: 900px) {
    .p-top-modal {
      font-size: 100%; } }
  .p-top-modal iframe {
    height: 483px;
    width: 860px; }
    @media only screen and (max-width: 900px) {
      .p-top-modal iframe {
        width: 100vw;
        height: auto; } }
  .p-top-modal .p-countdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; }
    @media only screen and (max-width: 900px) {
      .p-top-modal .p-countdown {
        display: block;
        margin-bottom: .5rem; } }
  .p-top-modal .p-countdown__title {
    display: inline-block;
    font-family: "Khand", sans-serif;
    font-size: 140%; }
  .p-top-modal .p-countdown__timer {
    font-family: "Megrim", cursive;
    display: inline-block;
    font-size: 160%;
    vertical-align: middle; }
    @media only screen and (max-width: 900px) {
      .p-top-modal .p-countdown__timer {
        display: block; } }
  .p-top-modal .l-countdown__time {
    display: inline-block;
    margin-right: 0;
    text-align: right; }
  .p-top-modal .p-countdown__ms, .p-top-modal .p-countdown__ss {
    min-width: 1.2em; }
  .p-top-modal .l-countdown__time-unit {
    display: inline;
    margin-left: -8px; }
    @media only screen and (max-width: 900px) {
      .p-top-modal .l-countdown__time-unit {
        margin-left: -5px; } }

.u-show-modal .p-top-modal::before {
  background-color: rgba(0, 0, 0, 0.8); }

.twitter--container {
  margin: auto; }
  @media only screen and (max-width: 900px) {
    .twitter--container {
      width: 90%; } }

#twitter-widget-0 {
  width: 100% !important;
  max-width: auto !important;
  height: 100%;
  margin: auto;
  display: block;
  overflow: scroll; }

@media only screen and (max-width: 900px) {
  .ignoresp {
    display: none; } }

.p-introduction__content > *:last-child {
  margin-bottom: 0; }

.p-introduction__content p {
  line-height: 2.2;
  margin-bottom: 2em;
  font-size: 1.2em;
  letter-spacing: 0.08em; }
  @media only screen and (max-width: 900px) {
    .p-introduction__content p {
      font-size: 100%;
      line-height: 1.5; } }

.p-movielist {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  max-width: 848px;
  padding: 10px;
  margin: 0 auto;
  list-style: none; }
  .p-movielist > *:last-child {
    margin-bottom: 0; }
  @media only screen and (max-width: 900px) {
    .p-movielist {
      display: block;
      width: 100%; } }
  .p-movielist li {
    position: relative;
    margin: 0 12px; }
    .p-movielist li::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      transform: translateY(-22px);
      z-index: 2;
      display: block;
      width: 60px;
      height: 60px;
      background-image: url(../img/movie/icon_movie_play.png);
      background-size: 100%;
      content: "";
      pointer-events: none; }
    @media only screen and (max-width: 900px) {
      .p-movielist li {
        margin: 0 auto 4.6875vw; }
        .p-movielist li::before {
          transform: translateY(-6.25vw);
          width: 18.75vw;
          height: 18.75vw; } }
    .p-movielist li a {
      display: block; }
      .p-movielist li a:hover {
        animation-name: flashing;
        animation-duration: 0.02s;
        animation-timing-function: linear;
        animation-delay: 0s;
        animation-direction: alternate;
        animation-iteration-count: 3; }
      .p-movielist li a:hover {
        opacity: .8; }
  .p-movielist__link {
    display: block;
    position: relative;
    background-color: #333;
    margin-bottom: 10px;
    width: 380px;
    height: 213px;
    cursor: pointer; }
    .p-movielist__link img {
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      z-index: 1; }
    .p-movielist__link::before, .p-movielist__link::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      height: 10px;
      display: block;
      margin: -1px;
      background-image: linear-gradient(to left, #e60000, #e60000 10px, transparent 11px, transparent 372px, #e60000 373px);
      content: ""; }
    .p-movielist__link::after {
      top: auto; }
    @media only screen and (max-width: 900px) {
      .p-movielist__link {
        width: 84.375vw;
        height: 47.34375vw;
        margin: 0 auto 3.90625vw; }
        .p-movielist__link::before, .p-movielist__link::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          height: 3.125vw;
          display: block;
          margin: -2px;
          background-image: linear-gradient(to left, #e60000, #e60000 3.125vw, transparent 3.28125vw, transparent 81.875vw, #e60000 82.03125vw);
          content: ""; }
        .p-movielist__link::after {
          top: auto; } }
  .p-movielist__title {
    padding-bottom: 10px; }
    @media only screen and (max-width: 900px) {
      .p-movielist__title {
        padding-bottom: 3.125vw;
        font-size: 3.75vw;
        text-align: center; } }

.p-movie-modal__content {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; }
  .p-movie-modal__content iframe {
    height: 483px;
    width: 860px; }
    @media only screen and (max-width: 900px) {
      .p-movie-modal__content iframe {
        width: 100vw;
        height: auto; } }

.p-character__character-item {
  width: calc( 100% - 130px);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; }
  .p-character__character-item img {
    width: auto; }
  @media only screen and (max-width: 900px) {
    .p-character__character-item {
      display: block;
      width: auto;
      padding: 0 20px; } }

.l-character__left {
  width: 360px; }
  @media only screen and (max-width: 900px) {
    .l-character__left {
      width: auto; } }

.l-character__body-wrapper {
  text-align: center;
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; }
  @media only screen and (max-width: 900px) {
    .l-character__body-wrapper {
      display: block;
      float: right;
      width: 70%; }
      .l-character__body-wrapper.l-no-face {
        float: none;
        text-align: center;
        margin: auto; }
      .l-character__body-wrapper .p-character__body {
        text-align: center;
        margin-top: 20px; }
      .l-character__body-wrapper img {
        height: 70vh;
        display: inline; } }

.p-character__name-en {
  color: #e60000; }

.p-character__name-jp {
  font-size: 400%; }
  @media only screen and (max-width: 900px) {
    .p-character__name-jp {
      font-size: 12vw; } }

.p-character__cv {
  line-height: 1;
  margin-top: 20px; }
  .p-character__cv span {
    color: #e60000; }
  .p-character__cv .p-character__cv-content {
    font-size: 200%;
    color: white;
    display: block;
    margin-top: 10px; }

.p-character__description {
  margin-top: 20px; }

.p-character__change-wrapper {
  width: 1070px;
  margin: auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; }
  @media only screen and (max-width: 900px) {
    .p-character__change-wrapper {
      width: auto; } }

.l-character__content_main {
  margin-top: 30px; }

.l-character__face-wrapper {
  width: 360px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; }
  .l-character__face-wrapper img {
    width: 180px; }
  @media only screen and (max-width: 900px) {
    .l-character__face-wrapper {
      width: 30%;
      margin: 20px 0;
      float: left; }
      .l-character__face-wrapper .p-character__face {
        margin: 0; }
        .l-character__face-wrapper .p-character__face img {
          width: 100%; } }

.l-character__face-1 {
  margin-top: 10px; }

.l-character__face-2 {
  margin-top: 80px; }

.p-character__nav {
  width: 130px;
  margin-top: 60px;
  text-align: center; }
  .p-character__nav a {
    display: block;
    margin-bottom: 20px;
    text-decoration: none; }
    .p-character__nav a img {
      border-bottom: 1px solid #e60000;
      filter: brightness(20%); }
    .p-character__nav a.active img {
      filter: none; }
    .p-character__nav a:not(.active):hover img {
      animation: flashing .05s infinite;
      filter: brightness(100%); }
  @media only screen and (max-width: 900px) {
    .p-character__nav {
      width: auto;
      margin-top: 0px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      padding: 20px 40px; }
      .p-character__nav a {
        width: 50%; } }

.p-character__thumbname {
  font-size: 13px;
  color: white; }

.l-staff ul, .l-staff li {
  list-style-type: none;
  margin: 0;
  padding: 0; }

.l-staff .l-container {
  width: 876px;
  padding: 0;
  padding-top: 20px; }

@media only screen and (max-width: 900px) {
  .l-staff {
    text-align: center; }
    .l-staff .l-container {
      width: 100%;
      display: block;
      padding: 0 10px; } }

.p-staff__tit {
  color: #e60000;
  font-size: 90%;
  margin-bottom: .5rem; }

.p-staff__name {
  font-size: 140%;
  margin-bottom: .5rem;
  line-height: 1; }

.p-staff__comment-tab {
  display: inline-block;
  margin-left: 20px;
  vertical-align: middle; }

.p-staff__comment-img {
  width: 85px; }

.p-staff__perform {
  font-size: 90%; }
  @media only screen and (max-width: 900px) {
    .p-staff__perform br {
      display: none; } }

.l-staff__left li {
  margin-bottom: 1rem; }

.l-staff__right li ul li {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 1.8rem;
  position: relative; }
  @media only screen and (max-width: 900px) {
    .l-staff__right li ul li {
      padding: 0 20px;
      text-align: center;
      display: block; } }

.p-staff__castname {
  font-size: 140%;
  display: inline-block; }
  @media only screen and (max-width: 900px) {
    .p-staff__castname {
      margin-left: 0; } }

.p-staff-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; }

.p-staff-modal__content {
  width: 700px;
  font-size: 80%; }
  @media only screen and (max-width: 900px) {
    .p-staff-modal__content {
      width: 100%;
      padding: 0 20px;
      height: 70%;
      overflow: scroll;
      -webkit-overflow-scrolling: touch; } }

.p-staff-detail {
  margin-bottom: 1rem;
  border-bottom: 1px solid #fff;
  padding-bottom: 1rem;
  text-align: center; }

.p-staff-detail__tit {
  display: inline-block; }

.p-staff-detail__name {
  display: inline-block;
  font-size: 200%;
  margin-left: 1rem; }

.p-staff-detail__comment {
  line-height: 2; }

.p-staff-detail__comment-q {
  margin-top: 1rem;
  color: #e60000; }

.p-staff__photo {
  margin-left: 15px; }
  @media only screen and (max-width: 900px) {
    .p-staff__photo {
      margin-left: 0;
      text-align: center; }
      .p-staff__photo img {
        width: 140px;
        margin-top: 20px;
        display: inline; } }

@media only screen and (max-width: 900px) {
  .l-comics {
    margin-bottom: 4.6875vw; } }

.p-comics .l-content {
  width: auto;
  max-width: 1100px;
  margin: 0 auto; }

.p-comics__writer {
  margin: 0 0 20px;
  font-size: 28px; }
  .p-comics__writer small {
    font-size: 0.55em; }
    .p-comics__writer small.accent {
      color: #e60000;
      margin-right: 20px; }
  @media (max-width: 1100px) {
    .p-comics__writer {
      font-size: 28px;
      padding: 0 20px;
      width: 880px; } }
  @media only screen and (max-width: 900px) {
    .p-comics__writer {
      font-size: 4.375vw;
      text-align: center;
      width: auto; } }

.p-comicslist {
  overflow: hidden;
  width: auto;
  margin: 0 auto;
  list-style: none; }
  .p-comicslist > *:last-child {
    margin-bottom: 0; }
  @media (max-width: 1100px) {
    .p-comicslist {
      width: 880px; } }
  @media only screen and (max-width: 900px) {
    .p-comicslist {
      width: 98.4375vw; } }
  .p-comicslist li {
    float: left;
    margin: 0 20px 50px;
    width: 180px; }
    @media only screen and (max-width: 900px) {
      .p-comicslist li {
        margin: 0 2.34375vw 7.8125vw;
        width: 28.125vw; } }

.l-campaign {
  margin-bottom: 0;
  padding: 0 0 50px;
  max-width: 100%;
  border: 5px #cc0000 solid; }
  @media only screen and (max-width: 900px) {
    .l-campaign {
      border: none;
      font-size: 2.5vw;
      padding: 0 0 6.25vw; } }
  .l-campaign__inner {
    width: 800px;
    margin: 0 auto; }
    @media only screen and (max-width: 900px) {
      .l-campaign__inner {
        width: auto; }
        .l-campaign__inner section:last-of-type {
          margin-bottom: 10.9375vw; } }

.p-campaign {
  background-image: url(../img/campaign/bg_campaign.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100%; }
  @media only screen and (max-width: 900px) {
    .p-campaign {
      background-image: url(../img/campaign/bg_campaign_sp.jpg); } }
  .p-campaign__header {
    margin-bottom: 70px; }
    @media only screen and (max-width: 900px) {
      .p-campaign__header {
        margin-bottom: 4.6875vw; } }
  .p-campaign__logo {
    margin: 0 auto;
    padding: 60px 0;
    width: 344px; }
    @media only screen and (max-width: 900px) {
      .p-campaign__logo {
        margin-bottom: 6.25vw;
        padding: 3.125vw;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.7); }
        .p-campaign__logo img {
          margin: 0 auto;
          width: 49.6875vw; } }
  .p-campaign__title {
    margin: 0 auto 5px;
    width: 776px;
    height: 50px;
    background-image: url(../img/campaign/tit_whoiscast_pc@2x.png);
    background-size: 100%;
    text-indent: -9999px; }
    @media only screen and (max-width: 900px) {
      .p-campaign__title {
        width: 69.21875vw;
        height: 20.3125vw;
        margin-left: 7.8125vw;
        margin-bottom: 3.90625vw;
        background-image: url(../img/campaign/tit_whoiscast_sp@2x.png);
        background-size: 100%; } }
  .p-campaign__subtitle {
    margin: 0 -100px 30px;
    text-indent: .03em;
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: .06em;
    transform: scaleX(0.8);
    color: #e60000; }
    @media only screen and (max-width: 900px) {
      .p-campaign__subtitle {
        margin: 0 -2.8125vw 6.25vw;
        text-align: left;
        text-indent: 0;
        font-size: 4.0625vw; } }
  .p-campaign__headersection h2 {
    font-size: 15px;
    font-weight: normal;
    margin-bottom: 5px; }
  .p-campaign__headersection p {
    line-height: 1.9; }
  @media only screen and (max-width: 900px) {
    .p-campaign__headersection h2 {
      font-size: 3.125vw;
      text-indent: .5em; }
    .p-campaign__headersection p {
      padding: 0 4.6875vw;
      line-height: 1.8;
      font-size: 3.125vw; } }
  .p-campaign__section {
    margin-bottom: 50px; }
    .p-campaign__section h2 {
      margin-bottom: 15px;
      background-image: url(../img/campaign/border_whoiscast.png);
      background-size: cover;
      font-size: 22px;
      font-weight: normal;
      text-align: center;
      line-height: 40px;
      color: #e60000; }
      .p-campaign__section h2.text--few {
        text-indent: 1em;
        letter-spacing: 1em; }
    @media only screen and (max-width: 900px) {
      .p-campaign__section {
        margin-bottom: 4.6875vw;
        padding: 0 4.6875vw; }
        .p-campaign__section h2 {
          margin-bottom: 2.34375vw;
          font-size: 3.4375vw;
          line-height: 6.25vw; } }
  .p-campaign__footersection h2 {
    color: #ccc;
    font-size: 18px;
    margin-bottom: 5px; }
  @media only screen and (max-width: 900px) {
    .p-campaign__footersection {
      padding: 0 4.6875vw; }
      .p-campaign__footersection h2 {
        font-size: 2.8125vw; } }
  .p-campaign__paragraph {
    margin-bottom: 100px;
    text-align: center; }
    @media only screen and (max-width: 900px) {
      .p-campaign__paragraph {
        margin-bottom: 6.25vw; } }
  .p-campaign__paragraph2 {
    margin: 30px auto;
    text-align: center; }
    @media only screen and (max-width: 900px) {
      .p-campaign__paragraph2 {
        margin: 4.6875vw auto; } }

.iframe-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  padding: 56.25% 0 0 0;
  border-top: 2px #e60000 solid; }
  .iframe-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  @media only screen and (max-width: 900px) {
    .iframe-wrapper {
      margin-bottom: 0; } }

.text--important {
  text-align: center;
  font-size: 24px; }
  @media only screen and (max-width: 900px) {
    .text--important {
      font-size: 3.125vw; } }

.text--small {
  text-align: right; }
  @media only screen and (max-width: 900px) {
    .text--small {
      font-size: 2.8125vw;
      text-align: center; } }

.text--center {
  text-align: center;
  font-size: 20px; }
  @media only screen and (max-width: 900px) {
    .text--center {
      font-size: 3.125vw; } }

.list-ordered {
  margin-left: 60px;
  list-style: none;
  counter-reset: number; }
  .list-ordered li {
    position: relative;
    margin-bottom: 30px;
    font-size: 20px; }
    .list-ordered li::before {
      position: absolute;
      top: 2px;
      left: -46px;
      color: #e60000;
      width: 26px;
      height: 26px;
      text-align: center;
      line-height: 1.1;
      border: 1px #e60000 solid;
      border-radius: 50%;
      font-family: "Khand", sans-serif;
      font-size: 24px;
      counter-increment: number;
      content: counter(number); }
  @media only screen and (max-width: 900px) {
    .list-ordered {
      margin-left: 5.15625vw; }
      .list-ordered li {
        margin-bottom: 4.6875vw;
        font-size: 2.96875vw; }
        .list-ordered li::before {
          top: 0.3125vw;
          left: -5.46875vw;
          width: 4.0625vw;
          height: 4.0625vw;
          font-size: 3.75vw; } }

.list-hasimage {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  list-style: none; }
  .list-hasimage li {
    font-size: 15px;
    line-height: 1.9;
    width: 380px; }
    .list-hasimage li img {
      margin-bottom: 10px; }
  @media only screen and (max-width: 900px) {
    .list-hasimage {
      display: block; }
      .list-hasimage > *:last-child {
        margin-bottom: 0; }
      .list-hasimage li {
        margin-bottom: 4.6875vw;
        width: 100%;
        font-size: 2.8125vw;
        overflow: hidden; }
        .list-hasimage li img {
          margin-bottom: 0; }
        .list-hasimage li .list-hasimage__text {
          text-align: center;
          padding: 4.6875vw; } }

.list-attention {
  color: #999;
  padding-left: 1.25em; }
  .list-attention li {
    line-height: 1.9; }

.button-twitter {
  display: block;
  width: 460px;
  margin: 0 auto; }
  @media only screen and (max-width: 900px) {
    .button-twitter {
      width: 71.875vw; } }

.inline--ignoresp {
  display: none; }
  @media only screen and (max-width: 900px) {
    .inline--ignoresp {
      display: inline; } }

.p-music__name {
  margin-bottom: 70px; }
  @media only screen and (max-width: 900px) {
    .p-music__name {
      text-align: center;
      margin-bottom: 7.8125vw; } }

.p-music__heading {
  display: block;
  margin-bottom: 50px;
  font-size: 45px;
  font-family: "Megrim", cursive;
  line-height: 1;
  letter-spacing: .08em;
  color: #e60000;
  text-transform: uppercase; }
  @media only screen and (max-width: 900px) {
    .p-music__heading {
      font-size: 6.25vw;
      margin-bottom: 6.25vw; } }

.p-music__music {
  font-size: 30px;
  line-height: 1.5;
  letter-spacing: .06em; }
  @media only screen and (max-width: 900px) {
    .p-music__music {
      font-size: 5.46875vw; } }

.p-music__row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; }
  .p-music__row > *:last-child {
    margin-bottom: 0; }

.p-music__col {
  width: calc((100% - 60px) / 2); }
  .p-music__col > *:last-child {
    margin-bottom: 0; }
  .p-music__col h3 {
    font-size: 27px;
    font-family: "Khand", sans-serif;
    letter-spacing: .15em;
    color: #e60000; }
  .p-music__col h4 {
    margin-bottom: 40px;
    font-weight: normal;
    font-size: 20px;
    font-family: "Khand", sans-serif;
    letter-spacing: .15em;
    color: #e60000; }
  .p-music__col h5 {
    font-weight: normal;
    text-rendering: optimizeLegibility;
    font-size: 15px;
    letter-spacing: .1em;
    color: #e60000;
    line-height: 2; }
  .p-music__col p {
    margin-bottom: 30px;
    font-size: 15px;
    letter-spacing: .1em;
    line-height: 2; }
  @media only screen and (max-width: 900px) {
    .p-music__col {
      width: 80vw;
      margin: 0 auto; }
      .p-music__col h3 {
        text-align: center;
        font-size: 5.46875vw; }
      .p-music__col h4 {
        margin-bottom: 3.125vw;
        font-size: 5.625vw; }
      .p-music__col h5 {
        font-size: 3.125vw;
        line-height: 1.9; }
      .p-music__col p {
        font-size: 3.125vw;
        line-height: 1.9; } }

.p-music__img {
  display: block;
  margin-bottom: 15px;
  max-width: 380px; }
  @media only screen and (max-width: 900px) {
    .p-music__img {
      max-width: none; } }

.p-music__links {
  padding-left: 45px; }
  .p-music__links dt, .p-music__links dd {
    margin-bottom: 5px; }
  @media only screen and (max-width: 900px) {
    .p-music__links {
      text-align: center;
      padding-left: 0;
      font-size: 3.125vw; }
      .p-music__links dd {
        margin-bottom: 7.8125vw; } }

.p-music__link {
  text-decoration: none;
  font-family: "Khand", sans-serif;
  letter-spacing: .15em; }

.p-music__artistname {
  font-weight: bold; }

.l-bd {
  width: 1080px;
  margin: auto; }
  @media only screen and (max-width: 900px) {
    .l-bd {
      width: auto; } }

.p-discography__tab_container {
  padding: 0 1rem; }

.p-discography__tab-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; }
  @media only screen and (max-width: 900px) {
    .p-discography__tab-items {
      flex-wrap: wrap; } }

.p-discography__tab-item {
  border-bottom: 1px solid #e60000;
  background-color: rgba(0, 0, 0, 0.5);
  width: 22%; }
  @media only screen and (max-width: 900px) {
    .p-discography__tab-item {
      width: 48%;
      margin-bottom: 1rem; } }
  .p-discography__tab-item a {
    display: block;
    text-align: center;
    text-decoration: none;
    padding: 15px 30px;
    font-size: 20px;
    color: white; }
    @media only screen and (max-width: 900px) {
      .p-discography__tab-item a {
        font-size: 3.4vw; } }

.u-discography__tab-item__active {
  background-color: #e60000; }

.p-discography__main_container {
  padding: 50px;
  position: relative; }
  @media only screen and (max-width: 900px) {
    .p-discography__main_container {
      box-sizing: border-box;
      padding: 0 1rem 2rem; } }

.p-discography__main_item {
  display: none; }
  @media only screen and (max-width: 900px) {
    .p-discography__main_item {
      display: none; } }
  .p-discography__main_item.u-discography__main_item__active {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap; }
    @media only screen and (max-width: 900px) {
      .p-discography__main_item.u-discography__main_item__active {
        display: block; } }

.l-discography__main-images {
  width: 440px; }
  .l-discography__main-images img {
    width: 100%;
    margin-bottom: 30px; }
  @media only screen and (max-width: 900px) {
    .l-discography__main-images {
      width: 100%; } }
  .l-discography__main-images-sub {
    display: flex;
    list-style: none; }
    .l-discography__main-images-sub li:first-child {
      margin-right: 10px; }

.l-discography__main-content {
  flex: 1 0 45%;
  flex-wrap: wrap;
  margin-left: 5%; }
  @media only screen and (max-width: 900px) {
    .l-discography__main-content {
      margin-left: 0; } }
  .l-discography__main-content img {
    width: auto;
    max-width: 100%;
    max-height: 300px; }
  .l-discography__main-content p {
    margin-bottom: 1em; }
  .l-discography__main-content ul {
    list-style-position: inside;
    margin-bottom: 1em; }
  .l-discography__main-content .l-main-title {
    color: #e60000;
    font-size: 130%;
    font-weight: bold;
    min-height: 55px;
    margin-top: 30px; }
  .l-discography__main-content .l-main-description {
    font-size: 80%; }

.l-discography__main-description {
  font-size: 80%;
  margin-top: 30px;
  margin-left: 5%; }

/* .p-movielist {
	@include container;
	@include fb;
	max-width: 848px;
	padding: 10px;
	margin: 0 auto;
	list-style: none;
	@include sp-screen {
		display: block;
		width: 100%;
	}
	li {
    	position: relative;
		margin: 0 12px;
	    &::before {
	      @include trbl;
	      transform: translateY(-22px);
	      z-index: 2;
	      display: block;
	      width: 60px;
	      height: 60px;
	      background-image: url(../img/movie/icon_movie_play.png);
	      background-size: 100%;
	      content: "";
	      pointer-events: none;
	    }
		@include sp-screen {
			margin: 0 auto 30 * $vs;
			&::before {
		        transform: translateY(-40 * $vs);
		        width: 120 * $vs;
		        height: 120 * $vs;
			}
		}
		a {
			display: block;
			@include wink;
			&:hover {
				opacity: .8;
			}
		}
	}
	&__link {
		display: block;
		position: relative;
		background-color: #333;
		margin-bottom: 10px;
		width: 380px;
		height: 213px;
		cursor: pointer;
		img {
			height: 100%;
			@include trbl;
			z-index: 1;
		}
		&::before,
		&::after {
			@include trbl;
			height: 10px;
			display: block;
			margin: -1px;
			background-image: linear-gradient(to left, $accentColor_txt, $accentColor_txt 10px, transparent 11px, transparent 372px, $accentColor_txt 373px);
			content: "";
		}
		&::after {
			top: auto;
		}
		@include sp-screen {
			width: 540 * $vs;
			height: 303 * $vs;
			margin: 0 auto 25 * $vs;
			&::before,
			&::after {
				@include trbl;
				height: 20 * $vs;
				display: block;
				margin: -2px;
				background-image: linear-gradient(to left, $accentColor_txt, $accentColor_txt 20 * $vs, transparent 21 * $vs, transparent 524 * $vs, $accentColor_txt 525 * $vs);
				content: "";
			}
			&::after {
				top: auto;
			}
		}
	}
	&__title {
		padding-bottom: 10px;
		@include sp-screen {
			padding-bottom: 20 * $vs;
			font-size: 24 * $vs;
			text-align: center;
		}
	}
}

.p-movie-modal__content {
	height: 100%;
	iframe {
		height: 483px;
		width: 860px;
		@include sp-screen {
			width: 100vw;
			height: auto;
		}
	}
	@include fb(center);
} */
/* @import "_news";
@import "_onair";
@import "_story";*/
